import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { Inputs } from "../../../Components";
import { useTitle } from "../../../Hooks";
import { useTranslation } from "react-i18next";
import { DateRangePickerComponent } from "../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { ButtonBase } from "@material-ui/core";
import "./SharedDocumentLog.scss";

function SharedDocumentsLogFilters({
  filter,
  setFilter,
  initialFilter,
  translationPath,
  parentTranslationPath,
}) {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t("shared-documents-log"));
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const initialSelected = {
    orderBy: 0,
    filterBy: "CreatedOn",
    documentSource: null,
    documentCategory: null,
    documentName: null,
    senderName: null,
    sendVia: null,
    sharedTimeFrom: null,
    sharedTimeTo: null,
    key: "selection",
    sharedBy: null,
    receiverName: null,
  };

  const [selected, setSelected] = useReducer(reducer, initialSelected);

  const onClearFiltersClicked = () => {
    setFilter({ id: "edit", value: initialFilter });
    setSelected({ id: "edit", value: initialSelected });
  };

  return (
    <React.Fragment>
      <div className="d-flex-column">
        <div className="d-flex">
          <div className="d-flex my-2">
            <div className="w-25 mx-2">
              <Inputs
                idRef="documentNameRef"
                labelValue={"document-name"}
                inputPlaceholder="document-name"
                value={selected.documentName || ""}
                onInputChanged={(event) =>
                  setSelected({
                    id: "documentName",
                    value: event.target.value,
                  })
                }
                onKeyUp={(event) => {
                  const documentName = event?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    setFilter({
                      id: "documentName",
                      value: documentName,
                    });
                  }, 700);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="w-25 mx-2">
              <Inputs
                idRef="documentSourceRef"
                labelValue={"document-source"}
                inputPlaceholder="document-source"
                value={selected.documentSource || ""}
                onInputChanged={(event) =>
                  setSelected({
                    id: "documentSource",
                    value: event.target.value,
                  })
                }
                onKeyUp={(event) => {
                  const documentName = event?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    setFilter({
                      id: "documentSource",
                      value: documentName,
                    });
                  }, 700);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="w-25 mx-2">
              <Inputs
                idRef="documentCategoryRef"
                labelValue={"document-category"}
                inputPlaceholder="document-category"
                value={selected.documentCategory || ""}
                onInputChanged={(event) =>
                  setSelected({
                    id: "documentCategory",
                    value: event.target.value,
                  })
                }
                onKeyUp={(event) => {
                  const documentCategory = event?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    setFilter({
                      id: "documentCategory",
                      value: documentCategory,
                    });
                  }, 700);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="w-25 mx-2">
              <Inputs
                idRef="sendViaRef"
                labelValue={"send-via"}
                inputPlaceholder="send-via"
                value={selected.sendVia || ""}
                onInputChanged={(event) =>
                  setSelected({
                    id: "sendVia",
                    value: event.target.value,
                  })
                }
                onKeyUp={(event) => {
                  const sendVia = event?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    setFilter({
                      id: "sendVia",
                      value: sendVia,
                    });
                  }, 700);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
          </div>

          <div className="clear-filter-button">
            <ButtonBase
              onClick={onClearFiltersClicked}
              id="onClearedAllFiltersref"
              className="btns theme-solid bg-danger clear-all-btn"
            >
              <span className="mdi mdi-filter-remove m-1" />
              {t(`clear-filters`)}
            </ButtonBase>
          </div>
        </div>

        <div className="d-flex my-2">
          <div className="w-25 mx-2">
            <Inputs
              idRef="senderNameRef"
              labelValue={"sender-name"}
              inputPlaceholder="sender-name"
              value={selected.senderName || ""}
              onInputChanged={(event) =>
                setSelected({
                  id: "senderName",
                  value: event.target.value,
                })
              }
              onKeyUp={(event) => {
                const senderName = event?.target?.value;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    id: "senderName",
                    value: senderName,
                  });
                }, 700);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>

          <div className="w-25 mx-2">
            <Inputs
              idRef="receiverNameRef"
              labelValue={"receiver-name"}
              inputPlaceholder="receiver-name"
              value={selected.receiverName || ""}
              onInputChanged={(event) =>
                setSelected({
                  id: "receiverName",
                  value: event.target.value,
                })
              }
              onKeyUp={(event) => {
                const receiverName = event?.target?.value;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    id: "receiverName",
                    value: receiverName,
                  });
                }, 700);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="w-25 mx-2">
            <Inputs
              idRef="sharedByRef"
              labelValue={"shared-by"}
              inputPlaceholder="shared-by"
              value={selected.sharedBy || ""}
              onInputChanged={(event) =>
                setSelected({
                  id: "sharedBy",
                  value: event.target.value,
                })
              }
              onKeyUp={(event) => {
                const sharedBy = event?.target?.value;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    id: "sharedBy",
                    value: sharedBy,
                  });
                }, 700);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="w-25 mx-2">
            <DateRangePickerComponent
              ranges={[filter]}
              labelValue={"shared-date"}
              onClearClicked={() => {
                setFilter({
                  id: "edit",
                  value: {
                    ...filter,
                    startDate: null,
                    endDate: null,
                  },
                });
              }}
              onDateChanged={(selectedDate) => {
                setFilter({
                  id: "edit",
                  value: {
                    ...filter,
                    startDate:
                      (selectedDate.selection &&
                        selectedDate.selection.startDate) ||
                      null,
                    endDate: new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day") || null
                    ),
                    key: "selection",
                  },
                });
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SharedDocumentsLogFilters;
