
import React  , {  useState , useEffect , useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from '../../../Hooks';
import { ActionsButtonsEnum , ActionsEnum  , ViewTypesEnum  } from '../../../Enums' ; 
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { Spinner  , ActionsButtonsComponent , ViewTypes    } from '../../../Components' ; 
import { PaginationComponent } from '../../../Components/PaginationComponent/PaginationComponent';
import { generateUniqueKey , GlobalHistory  , bottomBoxComponentUpdate } from '../../../Helper' ; 
import { formByIdGet } from '../../../Services' ; 
import { DevelopersCreatedOnFilter  , DevelopersTable , DevelopersCards } from './DevelopersViewComponent' ; 


 const parentTranslationPath = 'Developers';
 const translationPath = '';
  
  export const DevelopersView = () => { 

    const dispatch = useDispatch();
    
    const [activeFormType, setActiveFormType] = useState(1);
    const [sortBy, setSortBy] = useState(null);
    const [activeItem , setActiveItem ] = useState(null) ; 
    const [formBuilderForm ,setFormBuilderForm] = useState() ; 
    const [allDevelopers , setAllDevelopers ] = useState({result : [] , totalCount : 0 }) ; 


    const [isLoading , setIsLoading]  =  useState(false) ; 
    const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
    const [selectedOrderBy, setSelectedOrderBy] = useState({
      filterBy: orderFilter?.zeroMatchingSale?.filterBy,
      orderBy: orderFilter?.zeroMatchingSale?.orderBy,
    });

    const [orderBy, setOrderBy] = useState(
      selectedOrderBy.filterBy
        ? selectedOrderBy
        : { filterBy: 'CreatedOn', orderBy: 2 }
    );

    const [filter, setFilter] = useState({
      pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
      pageIndex: 0,
      filterBy: orderBy.filterBy || null,
      orderBy: orderBy.orderBy || null,
      createdBy: null,
    });

    const{ t } = useTranslation(parentTranslationPath);
    const [actionButtonsKey, setActionButtonsKey] = useState(generateUniqueKey());
    useTitle(t(`${translationPath}developers`));

    const onActionsButtonClicked = useCallback(
      (activeAction) => {
         if (activeAction === ActionsButtonsEnum[1].id) {
          GlobalHistory.push(
            `/home/developers/add`
          );
      }
      },
      []
    );

    const [activeActionType, setActiveActionType] = useState(
      (localStorage.getItem('ViewType') &&
        JSON.parse(localStorage.getItem('ViewType')).developers) ||
        ViewTypesEnum.cards.key
    );
    const onTypeChanged = useCallback(
      (activeType) => {
        let ViewTypeData = JSON.parse(localStorage.getItem('ViewType'));
        if (ViewTypeData) {
          ViewTypeData.developers = activeType;
          localStorage.setItem('ViewType', JSON.stringify(ViewTypeData));
        } else
          localStorage.setItem(
            'ViewType',
            JSON.stringify({ developers: activeType })
          );
        
          setActiveActionType(activeType);

      },
      [setActiveActionType]
    );

    const onPageIndexChanged = (pageIndex) => {
      setFilter((item) => ({ ...item, pageIndex }));
    };
  
    const onPageSizeChanged = (pageSize) => {
      setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    const getForm = useCallback(async () => {
      setIsLoading(true);
      const response = await formByIdGet({
        formname:'Developers'
        
      });
      if (!(response && response.status && response.status !== 200)) 
      setIsLoading(false);
    }, []);

    useEffect(() => {
      getForm();
     },[]);

    useEffect(() => {
    
        bottomBoxComponentUpdate(
          <PaginationComponent
            pageIndex={filter.pageIndex}
            pageSize={filter.pageSize}
            totalCount={allDevelopers.totalCount}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
          />
        );
    });
  
    useEffect(
      () => () => {
        bottomBoxComponentUpdate(null);
      },
      []
    );

    return (
      <div className='view-wrapper developers-view'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className='d-flex-column'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='section'>
                  <ButtonBase
                    className='btns theme-solid'
                    idRef='addnewDevelopersRef'
                    id='addnewDevelopers'
                    onClick={() => {
                      GlobalHistory.push(
                        '/home/developers/add'
                      );
                    }}
                  >
                    <span>
                      {t(`${translationPath}add-new-developer`)}
                    </span>
                  </ButtonBase>
                  <ButtonBase
                    className='btns theme-solid'
                    idRef='addnewDevelopersRef'
                    id='addnewDevelopers'
                    onClick={() => {
                      GlobalHistory.push(
                        `/home/developers/developer-profile-edit?id=${(activeItem && activeItem.id)||1}&formType=${(activeItem && activeItem.formType)|| 135 }`
                      );
                    }}
                  >
                    <span>
                      {t(`${translationPath}edit-developer`)}
                    </span>
                  </ButtonBase>
              </div>
                <div className='section autocomplete-section'>
                  <div className='d-flex-column px-2 w-100 p-relative mb-2'>
                    <div className='w-100 p-relative mb-2'>
                    </div>
                    <div className='agentSection pl-5-reversed'>
                      <div className='mr-1-reversed'>
                        
                      </div>
                      <div className='w-100 mr-1-reversed'>
                       
                      </div>
                      <div className='w-100 mr-1-reversed'>
                      </div>
                    </div>
                  </div>
  
                  <div className='view-search-wrapper'>
                    <ViewTypes
                      onTypeChanged={onTypeChanged}
                      initialActiveType={activeActionType}
                      activeTypes={[
                        ViewTypesEnum.tableView.key,
                        ViewTypesEnum.cards.key,
                      ]}
                      className='mb-3'
                    />
                  </div>
                </div>
            </div>
          <DevelopersCreatedOnFilter
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            orderFilter={orderFilter}
            selectedOrderBy={selectedOrderBy}
            setOrderBy={setOrderBy}
            setSelectedOrderBy={setSelectedOrderBy} 
            orderBy={orderBy}
            />
          </div>
          <>
              {(
                (activeActionType === ViewTypesEnum.tableView.key && (
                  <>
                     <DevelopersTable
                      data={allDevelopers }
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                      setSortBy={setSortBy}
                      filter={filter}
                      isLoading={isLoading}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                     />
                  </>
                )) ||
                (activeActionType === ViewTypesEnum.cards.key && (
                  <>
                    <div className='view-wrapers'>
                      <DevelopersCards
                        data={allDevelopers && [{ id :111 , name : 'fsef' } ,  {id :112 , name : '2222222' }] }
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                      />
                    </div>
                  </>
                ))
              )}
          </>
        </div>
      </div>
    );

    
  } ; 
  