import moment from "moment";
import React from "react";
const ShareDocumentLogHeader = [
  {
    id: 1,
    label: "NO.",
    input: "shareDocumentLogId",
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 2,
    label: "related-id",
    input: "documentSourceId",
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 3,
    label: "document-source",
    input: "documentSource",
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 4,
    label: "document-category",
    input: "documentCategory",
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 5,
    label: "document-name",
    input: "documentName",
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 6,
    label: "shared-by",
    input: "senderName",
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 7,
    label: "send-via",
    input: "sendVia",
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 8,
    label: "sender-name",
    input: "senderName",
    isSortable: true,
    isHiddenFilter: false,
    isDate: false,
  },
  {
    id: 9,
    label: "receiver-name",
    input: "receiverName",
    isHiddenFilter: false,
    isDate: false,
    component: (item) => (
      <span>
        {item?.receiverName.split(",").map((el, index) => (
          <span key={index} className="table-capsule mr-1 fw-simi-bold">
            {el.trim() || ""}
          </span>
        ))}
      </span>
    ),
  },
  {
    id: 10,
    label: "shared-date",
    isHiddenFilter: false,
    component: (item) => (
      <span>
        {item?.sharedTime ? moment(item.sharedTime).format("YYYY-MM-DD") : ""}
      </span>
    ),
  },
  {
    id: 11,
    label: "shared-time",
    isHiddenFilter: false,
    component: (item) => (
      <span>
        {item?.sharedTime ? moment(item.sharedTime).format(" hh:mm:ss a") : ""}
      </span>
    ),
  },
];

export default ShareDocumentLogHeader;
