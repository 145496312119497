import React from 'react';
import './PageHeading.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

 export const PageHeading = ({parentTranslationPath, translationPath, headerTitle, subTitle,
  subTitleClasses, headerTitleClasses, wrapperClasses , titleWithPercentage
}) => {
  const { t } = useTranslation(parentTranslationPath);
  
  return (
    <div className={`PageHeading mb-3 ${wrapperClasses || ''}`}>
      {
        (!titleWithPercentage && titleWithPercentage !== ''  ) ?  
        <h5 className={`fw-simi-bold m-0 mb-1 ${headerTitleClasses || ''}`}>{headerTitle? t(`${translationPath}${headerTitle}`) : ''}</h5> 
        :
        (
        <div className='title-with-chip mb-2'>
         <h5 className={`fw-simi-bold m-0 mb-1 header-title`}>{headerTitle? t(`${translationPath}${headerTitle}`) : ''}</h5> 
         {
           titleWithPercentage !=='' ? ( <span className='title-chip'> { titleWithPercentage }</span>) :(<span className=''></span>)
         }

       
        </div>)}
      <p className={`text-tertiary heading-subtitle ${subTitleClasses || ''}`}>{subTitle? t(`${translationPath}${subTitle}`) : ''}</p>
    </div>

  );
};

PageHeading.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  headerTitle: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleClasses: PropTypes.string,
  headerTitleClasses: PropTypes.string,
  wrapperClasses: PropTypes.string,
  titleWithPercentage: PropTypes.string,
};
PageHeading.defaultProps = {
  translationPath: "",
  parentTranslationPath: "",
  headerTitle: "",
  subTitle: "",
  subTitleClasses: "",
  headerTitleClasses: "",
  wrapperClasses: "",
  titleWithPercentage: "",
};
