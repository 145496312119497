import React, {
  useCallback, useEffect, useReducer, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase, Chip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  AutocompleteComponent,
  DataFileAutocompleteComponent,
  DatePickerComponent,
  DialogComponent,
  Inputs,
  Spinner,
  SwitchComponent,
  UploaderActivitieFileComponent,
  SelectComponet
} from '../../../../../../../Components';
import {
  getDownloadableLink,
  getErrorByName, GetParams, GlobalTranslate, showError, showSuccess, floatHandler
} from '../../../../../../../Helper';
import {
  ActivitiesRelatedToActivitiesTypeEnum, LeadsStatusEnum, ReminderTypesEnum, ActivityTypeReminderToEnum
} from '../../../../../../../Enums';
import {
  OrganizationUserSearch,
  GetAllActivityTypes,
  CreateActivity,
  EditActivity,
  GetAllLeadsByContactId,
  GetActivityTypeBasedOnRelatedType,
  GetActivityNotification,
  contactsDetailsGet
} from '../../../../../../../Services';
import { GetActivityTypeById } from '../../../../../../../Services/ActivitiesTypesServices';
import { ActivityNotificationsDialog } from "./ActivityNotificationsDialog/ActivityNotificationsDialog";
export const ActivitiesManagementDialog = ({
  activeItem,
  onSave,
  parentTranslationPath,
  translationPath,
  open,
  close,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const loginResponse = useSelector((sa) => sa.login.loginResponse);
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const searchTimer = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFilesDialogOpen, setIsFilesDialogOpen] = useState(false);
  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [AssignActivity, setAssignActivity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reminder, setReminder] = useState();
  const [activityRelatedToList, setActivityRelatedToList] = useState([]);
  const [isRelatedToNotSelected, setIsRelatedToNotSelected] = useState(true);
  const [activityTypeReminders, setActivityTypeReminders] = useState(null)
  const [reminderAmountOfTimeTypes] = useState([
    {
      key: 1,
      value: 'minutes',
      momentKey: 'minutes',
    },
    {
      key: 2,
      value: 'hours',
      momentKey: 'hours',
    },
    {
      key: 3,
      value: 'days',
      momentKey: 'days',
    },
  ]);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [rols, setrols] = useState([]);
  const [loadings, setLoadings] = useReducer(reducer, {
    activityAssignments: false,
    activityTypes: false,
    units: false,
    relatedUnits: false,
    relatedLeads: false,
    relatedContacts: false,
  });
  const [selected, setSelected] = useReducer(reducer, {
    activityAssign: null,
    activityType: null,
    unit: null,
    activeFormType: 2,
    relatedUnit: null,
    relatedLead: null,
    relatedContact: null,
    reminderPersons: [],
  });
  const [data, setData] = useReducer(reducer, {
    activityAssignments: [],
    activityTypes: [],
    units: [],
    relatedLeads: [],
    relatedContacts: [],
    relatedUnits: [],
    notifications: null
  });
  const [state, setState] = useReducer(reducer, {
    assignAgentId: null,
    activityTypeId: null,
    unitId: null,
    relatedLeadNumberId: null,
    relatedContactNumberId: null,
    relatedUnitNumberId: null,
    activityDate: moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
    subject: null,
    comments: null, 
    isOpen: true,
    activityReminders: [],
    createdByName: (loginResponse && loginResponse.fullName) || null,
    fileId: null,
    fileName: null,
  });

  const defaultActivityReminderItem = useRef({
    reminderType: 1,
    contactId: null,
    usersId: null,
    reminderTime: state.activityDate,
    reminderAmountOfTimeType: 1,
  });

  const getTimeValue = (item) => {
    if (!state.activityDate) return 0;
    const currentTimeAmountType = reminderAmountOfTimeTypes.find(
      (items) => items.key === (item.reminderAmountOfTimeType || 1)
    );

    return moment(state.activityDate).diff(item.reminderTime, currentTimeAmountType.momentKey);
  };


  const schema = Joi.object({
    assignAgentId: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}activity-assign-to-is-required`),
      }),
    activityTypeId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}activity-type-is-required`),
      }),
    relatedUnitNumberId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected) return helpers.error('state.required');
        return value;
      })
      .messages({
        'state.required': t(`${translationPath}related-to-is-required-one-at-least`),
      }),
    relatedLeadNumberId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected) return helpers.error('state.required');
        return value;
      })
      .messages({
        'state.required': t(`${translationPath}related-to-is-required-one-at-least`),
      }),
      relatedContactNumberId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected) return helpers.error('state.required');
        return value;
      })
      .messages({
        'state.required': t(`${translationPath}related-to-is-required-one-at-least`),
      }),
    activityDate: Joi.date().required().greater(Date.now())
      .messages({
        'date.base': t(`${translationPath}activity-date-is-required`),
        'date.greater': t(`${translationPath}choose-time-after-now`),
      }),
    activityReminders:
      (reminder &&
        Joi.array().items(
          Joi.object({
            reminderType: Joi.any(),
            contactId: Joi.any(),
            usersId: Joi.any(),
            reminderTime: Joi.any(),
          })
            .custom((value, helpers) => {
              if (
                !value.contactId &&
                !value.usersId &&
                ((value.reminderTime && getTimeValue(value)) || 0) > 0
              )
                return helpers.error('state.userNotSet');
              if (
                (value.contactId || value.usersId) &&
                ((value.reminderTime && getTimeValue(value)) || 0) === 0
              )
                return helpers.error('state.timeIsZero');
              return value;
            })
            .messages({
              'state.userNotSet': t(`${translationPath}reminder-for-is-required`),
              'state.timeIsZero': t(`${translationPath}time-must-be-greater-than-zero`),
            })
        )) ||
      Joi.any(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const getAllActivityAssignments = useCallback(
    async (value, selectedValue) => {
      setLoadings({ id: 'activityAssignments', value: true });
      const res = await OrganizationUserSearch({
        ...filter,
        rolesIds: rols,
        name: value,
      });
      if (!(res && res.status && res.status !== 200)) {
        setData({
          id: 'activityAssignments',
          value:
            (selectedValue &&
              ((res && res.result && [...res.result, selectedValue]) || [selectedValue])) ||
            (res && res.result) ||
            [],
        });
      } else {
        setData({
          id: 'activityAssignments',
          value: [],
        });
      }
      setLoadings({ id: 'activityAssignments', value: false });
    },
    [filter, rols]
  );
  const getAllRelatedLeads = useCallback(async () => {
    setLoadings({ id: 'relatedLeads', value: true });
    const response = await GetAllLeadsByContactId(
      { pageIndex: 0, pageSize: 100, leadStatus: LeadsStatusEnum.Open.status },
      +GetParams('id')
    );
    if (!(response && response.status && response.status !== 200))
      setData({ id: 'relatedLeads', value: (response && response.result) || [] });
    else setData({ id: 'relatedLeads', value: [] });

    setLoadings({ id: 'relatedLeads', value: false });
  }, []);

  const getAllRelatedContacts = useCallback(
    async () => {
      setLoadings({ id: 'relatedContacts', value: true });

      const res = await contactsDetailsGet({ id: +GetParams('id') });
      if (!(res && res.status && res.status !== 200)) {
        setData({ id: 'relatedContacts', value: (res &&[res]) || [] }); 
      }
        else setData({ id: 'relatedContacts', value: [] });
      setLoadings({ id: 'relatedContacts', value: false });
    }, []);

  const GetActivityType = useCallback(async () => {
    setLoadings({ id: 'activityTypes', value: true });
    if (selected.activityType !== null) {
      const res = await GetActivityTypeById(selected.activityType.activityTypeId);
      if (!(res && res.status && res.status !== 200)) {
        setReminder(res.withReminder);
        setActivityTypeReminders(res.activityTypeReminders);
        const relatedToResponse = res.relatedTo&&res.relatedTo.filter(item=>(item.relatedToName==='Contact'||item.relatedToName==='Lead'));

        setActivityRelatedToList(relatedToResponse || [])
      } else {
        setReminder(res.withReminder);
      }
    } else

      setLoadings({ id: 'activityTypes', value: false });
  }, [selected.activityType]);

  const getActivityNotification = useCallback(
    async (activityId) => {
      setIsLoading(true);
      const response = await GetActivityNotification(activityId)
      if (!(response && response.status && response.status !== 200)) {
        setData({ id: 'notifications', value: response || null });
       } else setData({ id: 'notifications', value: null });

      setIsLoading(false);
    },
    []
  );

  const getAllActivityTypes = useCallback(async () => {
    setLoadings({ id: 'activityTypes', value: true });
    
    const relatedToContactResponse = await GetActivityTypeBasedOnRelatedType(ActivitiesRelatedToActivitiesTypeEnum.contact.key);
    if (!(relatedToContactResponse && relatedToContactResponse.status && relatedToContactResponse.status !== 200)) {
      setData({
        id: 'activityTypes',
        value: relatedToContactResponse,
      });
    } 

    const relatedToLeadResponse = await GetActivityTypeBasedOnRelatedType(ActivitiesRelatedToActivitiesTypeEnum.lead.key);
    if (!(relatedToLeadResponse && relatedToLeadResponse.status && relatedToLeadResponse.status !== 200)) {
      setData({
        id: 'activityTypes',
        value: [...data.activityTypes, ...relatedToLeadResponse] ,
      });
    } 

    setLoadings({ id: 'activityTypes', value: false });
  }, []);

  const convertToUploderReview = (item) => {
    if(item.length>0){
      const files = item.filter(item=>(item.uuid!==null));
      const uploadedFiles = files.map((element, index) => ({
        uuid: element.uuid, 
        fileName: element.fileName,
        isNotUploaded: element.isNotUploaded,
      }));
      setUploadedFile(uploadedFiles);
      setState({ id: 'files', value: files })
    }
  };

  const saveHandler = async (event) => {

    const saveState = { ...state };
    saveState.activityReminders = saveState.activityReminders.filter(
      (item) => item.reminderTime !== 0 && (item.usersId || item.contactId)
    );

    event.preventDefault();

    setIsSubmitted(true);
    if (state.activityDate && (state.activityDate <= moment().format('YYYY-MM-DDTHH:mm:ss'))) {
      showError(t(`${translationPath}choose-time-after-now`));
      setIsLoading(false);
      return;
    }
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    const res =
      (activeItem && activeItem.activityId && (await EditActivity(activeItem.activityId, state))) ||
      (await CreateActivity(state));
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      if (activeItem && activeItem.activityId)
        showSuccess(t`${translationPath}activity-updated-successfully`);
      else showSuccess(t`${translationPath}activity-created-successfully`);
      if (onSave) onSave();
    } else if (activeItem && activeItem.activityId)
      showError(t(`${translationPath}activity-update-failed`));
    else showError(t`${translationPath}activity-create-failed`);
  };
  
  const reminderTimeCalculateHandler = useCallback(
    (item, value, type, activityDate) => {
      const currentTimeAmountType = reminderAmountOfTimeTypes.find(
        (items) => items.key === (type || item.reminderAmountOfTimeType || 1)
      );
      let newTimeValue = moment(activityDate || state.activityDate)
        .add(-value, currentTimeAmountType.momentKey)
        .format('YYYY-MM-DDTHH:mm:ss');
      if (moment(newTimeValue).isBefore(moment().format('YYYY-MM-DDTHH:mm:ss')))
        newTimeValue = moment().format('YYYY-MM-DDTHH:mm:ss');
      setState({
        id: 'activityReminders',
        value: state.activityReminders,
      });
      return newTimeValue;
    },
    [reminderAmountOfTimeTypes, state.activityDate, state.activityReminders]
  );

  const reminderTimeChangeHandler = useCallback(
    (item) => (event) => {
      const value = floatHandler(event.target.value, 0);
      item.reminderTime = reminderTimeCalculateHandler(item, value);
    },
    [reminderTimeCalculateHandler]
  );



  const activityTimeChangeHandler = (activityDate)=>{
    if (state.activityReminders){
         const mappedActiveItemReminders = state.activityReminders.map(item=>{
         return {...item,
         reminderTime: reminderTimeCalculateHandler(item,
          getTimeValue(item) || 0,
          1,
          activityDate)
               }})
      setState({
        id: 'activityReminders',
        value: mappedActiveItemReminders,
      });

    }
  }

  const reminderDecrementHandler = useCallback(
    (index) => () => {
      const localActivityReminders = [...state.activityReminders];
      localActivityReminders.splice(index, 1);
      setState({
        id: 'activityReminders',
        value: localActivityReminders,
      });
    },
    [state.activityReminders]
  );
  const reminderIncrementHandler = useCallback(
    (index) => () => {
      const localActivityReminders = [...state.activityReminders];
      localActivityReminders.splice(index, 0, { ...defaultActivityReminderItem.current });
      setState({
        id: 'activityReminders',
        value: localActivityReminders,
      });
    },
    [state.activityReminders]
  );

  const relatedToChangeHandler = (item, newValue)=>{


    setState({
      id: (((item.relatedToName==='Lead') && 'relatedLeadNumberId' ) ||
      ((item.relatedToName==='Contact') && 'relatedContactNumberId' ) ||
      ''),
      value: (
        newValue && (
        (item.relatedToName === 'Lead' && newValue.leadId) ||
        (item.relatedToName === 'Contact' && newValue.contactsId))||
        null
        )
      });
      
      setSelected({
        id: (((item.relatedToName==='Lead') && 'relatedLead' ) ||
        ((item.relatedToName==='Contact') && 'relatedContact' ) || ''),
        value: newValue
      });
  

    if(!reminder){
      const localReminderPersons = [...selected.reminderPersons];
      const localReminderPersonIndex = localReminderPersons.findIndex(
        (item) => item.type === 'contact'
      );

      if (newValue && item.relatedToName === 'Lead') {
        const itemToPush = {
          id: newValue.lead && newValue.lead.contact_name && newValue.lead.contact_name.id,
          value:
          (newValue.lead &&
            newValue.lead.contact_name &&
            newValue.lead.contact_name.name) ||
            '',
          type: 'contact',
        };
        if (localReminderPersonIndex !== -1)
          localReminderPersons[localReminderPersonIndex] = itemToPush;
        else localReminderPersons.push(itemToPush);
        setSelected({ id: 'reminderPersons', value: localReminderPersons });
      } else if (localReminderPersonIndex !== -1) {
        localReminderPersons.splice(localReminderPersonIndex, 1);
        setSelected({ id: 'reminderPersons', value: localReminderPersons });
      }
    }
  }

  useEffect(() => {
    getAllActivityTypes();
    getAllRelatedLeads();
    getAllRelatedContacts()
    GetActivityType();
  }, [
    getAllActivityTypes,
    getAllRelatedLeads,
    getAllRelatedContacts,
    GetActivityType
  ]);

  useEffect(() => {
    getAllActivityAssignments();
  }, [
    getAllActivityAssignments,
    rols
  ]);

  useEffect(() => {
    if (activeItem) {
      setState({
        id: 'edit',
        value: {
          assignAgentId: activeItem.agentUserId,
          activityTypeId: activeItem.activityTypeId,
          unitId: activeItem.unitId,
          relatedLeadNumberId: activeItem.relatedLeadNumberId,
          relatedUnitNumberId: activeItem.relatedUnitNumberId,
          relatedContactNumberId: activeItem.relatedContactNumberId,
          activityDate: activeItem.activityDate,
          subject: activeItem.subject,
          comments: activeItem.comments,
          isOpen: activeItem.isOpen,
          activityReminders: activeItem.activityReminders || [],
          createdByName: activeItem.createdBy,
          fileId: activeItem.fileId,
          fileName: activeItem.fileName,
          files: activeItem.activityFiles || []
        },
      });
      setEdit(true);
      displayActivityInitialFiles(activeItem.activityFiles);
    }
  }, [activeItem]);

  useEffect(()=>{
    if(activeItem){
      getActivityNotification(activeItem.activityId)
    }
  },[activeItem])

  useEffect(() => {
    if (activeItem) {
      const activityAssign = {
        id: activeItem.agentUserId,
        userName: activeItem.agentUsername,
        fullName: activeItem.agentName,
      };

      
      const relatedLeadData = data.relatedLeads &&
    data.relatedLeads.find(item=>activeItem.relatedLeadNumberId===item.leadId);


      const reminderPersons = [
        { id: activityAssign.id, value: activityAssign.fullName, type: 'user' },
      ];

      
      const relatedLeadContactId = relatedLeadData&&relatedLeadData.lead&&
      relatedLeadData.lead.contact_name&&relatedLeadData.lead.contact_name.id;

      const relatedLead =
      (activeItem.relatedLeadNumberId && {
        leadId: activeItem.relatedLeadNumberId,
        lead: {
          contact_name: {
            name: activeItem.contactName || 'N/A',
          },
        }})||
      null;
      if (relatedLead&& !activeItem) {
      reminderPersons.push({
        id: relatedLead.contactId,
        value: relatedLead.lead.contact_name.name,
        type: 'contact',
      });
    }

      setSelected({
        id: 'edit',
        value: {
          ...selected,
          activityAssign: { fullName: activeItem.agentName },
          activityType: activeItem.activityType,
          activeFormType: activeItem.relatedUnitNumberId ? 1 : 2,
          unit: {
            propertyName:
              (data.units &&
                data.units[0] &&
                data.units[0].propertyName &&
                data.units.filter((item) => item.id === activeItem.unitId)[0].propertyName) ||
              '',
          },
          relatedLead: activeItem.relatedLeadNumberId && {
            lead: {
              contact_name: {
                name:

                relatedLeadData&&relatedLeadData.lead &&relatedLeadData.lead.contact_name&&relatedLeadData.lead.contact_name.name || null
              },
            },
          },
          relatedUnit: {
            propertyName:
              activeItem.relatedUnitNumberId &&
              data.relatedUnits &&
              data.relatedUnits[0] &&
              data.relatedUnits.filter((item) => item.id === activeItem.relatedUnitNumberId)[0]
                .propertyName,
          },
          createdByName: activeItem.createdByName,
          reminderPersons,
          relatedContact:(activeItem.relatedContactNumberId && {
            contactsId: activeItem.relatedContactNumberId,
            contact: {
              company_name: activeItem.contactName || 'N/A',
              first_name: activeItem.contactName || 'N/A',
              last_name: activeItem.contactName || 'N/A',
            },
          }) ||
          null
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem, data]);

  useEffect(() => {
    if (
      !activeItem && activityTypeReminders && activityTypeReminders.length > 0
    ) {
      const mappedActivityTypeReminders = activityTypeReminders.map(item => {
        return {
          reminderType: item.reminderType,
          contactId: null,
          usersId: null,
          reminderTime: reminderTimeCalculateHandler(item,
            (item.reminderTime || 0),
            1),
          reminderAmountOfTimeType: 1,
        }
      })
      setState({
        id: 'activityReminders',
        value: mappedActivityTypeReminders,
      });
    } else if (!activeItem && state.activityReminders && state.activityReminders.length === 0 && activityTypeReminders && activityTypeReminders.length === 0) {
      setState({
        id: 'activityReminders',
        value: [{ ...defaultActivityReminderItem.current }],
      });
    }
  }, [activityTypeReminders, state.activityDate]);

  useEffect(() => {
    if (state.activityTypeId == null && !activeItem) {
      setAssignActivity(true);
      setState({
        id: 'assignAgentId',
        value: null,
      });
      setSelected({ id: 'activityAssign', value: null });
    } else setAssignActivity(false);
  }, [state.activityTypeId]);

  const fileDeleted = useCallback(
    (item, index) => () => {


      const selectedFileIndex = uploadedFile.findIndex((element) => element.uuid === (item.fileId||item.uuid));
      if (selectedFileIndex !== -1) {
        const localFiles = [...uploadedFile];
        localFiles.splice(selectedFileIndex, 1);
        setUploadedFile(localFiles);
        const stateLocalFiles = [...state.files];
        stateLocalFiles.splice(selectedFileIndex, 1);
        setState({ id: 'files', value: stateLocalFiles })
      }

    },
    [uploadedFile]
  );

  const download = (value) => () => {
    const link = document.createElement('a');
    link.setAttribute('download', value.fileName);
    link.href = getDownloadableLink(value.uuid);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const displayActivityInitialFiles = (initialFiles)=>{
    if(initialFiles && initialFiles.length>0){
      const files = initialFiles.map((element, index) => ({
        uuid: element.fileId,
        fileName: element.fileName,
        isNotUploaded: true,
      }));
      setUploadedFile(files);
    }
  }

  useEffect(() => {
    setIsRelatedToNotSelected(!(state.relatedLeadNumberId ||
      state.relatedContactNumberId));
  }, [state]);
  

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          close();
        }}
        className='activities-management-dialog-wrapper'
      >
        <Spinner isActive={isLoading} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(`${translationPath}${(activeItem && 'edit-activity') || 'add-new-activity'}`)}
          </DialogTitle>
          <DialogContent>
            <div className='dialog-content-wrapper'>
              <div className='dialog-content-item'>
                <AutocompleteComponent
                  idRef='activityTypeIdRef'
                  labelValue='activity-type'
                  labelClasses='Requierd-Color'
                  selectedValues={selected.activityType}
                  multiple={false}
                  isDisabled={edit}
                  data={data.activityTypes}
                  displayLabel={(option) => option.activityTypeName || ''}
                  groupBy={(option) => option.categoryName || ''}
                  getOptionSelected={(option) => option.activityTypeId === state.activityTypeId}
                  withoutSearchButton
                  helperText={getErrorByName(schema, 'activityTypeId').message}
                  error={getErrorByName(schema, 'activityTypeId').error}
                  isLoading={loadings.activityTypes}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setState({
                      id: 'subject',
                      value: (newValue && newValue.activityTypeName) || null,
                    });
                    setSelected({ id: 'activityType', value: newValue });
                    setState({
                      id: 'activityTypeId',
                      value: (newValue && newValue.activityTypeId) || null,
                    });
                    const assignedToActivityTypeRoles = ((newValue && newValue.assignedToActivityTypeRoles) || []);
                    const rolesIds = (assignedToActivityTypeRoles && assignedToActivityTypeRoles.map((item) => item.rolesId || []));
                    setrols(rolesIds);
                    setState({
                      id: 'assignAgentId',
                      value: (newValue && newValue.id) || null,
                    });
                    setSelected({ id: 'activityAssign', value: null });
                    setState({ id: 'activityReminders', value: [] });
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <AutocompleteComponent
                  idRef='assignAgentIdRef'
                  labelValue='activity-assign-to'
                  labelClasses='Requierd-Color'
                  isDisabled={AssignActivity}
                  selectedValues={selected.activityAssign}
                  multiple={false}
                  data={data.activityAssignments}
                  displayLabel={(option) => option.fullName || ''}
                  renderOption={(option) =>
                    ((option.userName || option.fullName) &&
                      `${option.fullName} (${option.userName})`) ||
                    ''}
                  getOptionSelected={(option) => option.id === state.assignAgentId}
                  withoutSearchButton
                  helperText={getErrorByName(schema, 'assignAgentId').message}
                  error={getErrorByName(schema, 'assignAgentId').error}
                  isLoading={loadings.activityAssignments}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllActivityAssignments(value, selected.activityAssign);
                    }, 700);
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setSelected({ id: 'activityAssign', value: newValue });

                    const localReminderPersons = [...selected.reminderPersons];
                    const localReminderPersonIndex = localReminderPersons.findIndex(
                      (item) => item.type === 'user'
                    );
                    if (newValue) {
                      const itemToPush = {
                        id: newValue.id,
                        value: newValue.fullName,
                        type: 'user',
                      };
                      if (localReminderPersonIndex !== -1)
                        localReminderPersons[localReminderPersonIndex] = itemToPush;
                      else localReminderPersons.push(itemToPush);
                      setSelected({ id: 'reminderPersons', value: localReminderPersons });
                    } else if (localReminderPersonIndex !== -1) {
                      localReminderPersons.splice(localReminderPersonIndex, 1);
                      setSelected({ id: 'reminderPersons', value: localReminderPersons });
                    }

                    setState({
                      id: 'assignAgentId',
                      value: (newValue && newValue.id) || null,
                    });
                  }}
                />
              </div>
              {/* <div className='dialog-content-item'>
                <DataFileAutocompleteComponent
                  idRef='unitIdRef'
                  labelValue='unit'
                  selectedValues={selected.unit}
                  multiple={false}
                  data={data.units}
                  displayLabel={(option) => option.propertyName || ''}
                  renderFor='unit'
                  getOptionSelected={(option) => option.id === state.unitId}
                  withoutSearchButton
                  helperText={getErrorByName(schema, 'unitId').message}
                  error={getErrorByName(schema, 'unitId').error}
                  isLoading={loadings.units}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllUnits(value);
                    }, 700);
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setSelected({ id: 'unit', value: newValue });
                    setState({
                      id: 'unitId',
                      value: (newValue && newValue.id) || null,
                    });
                  }}
                />
              </div> */}
              <div className='dialog-content-item related-to-wrapper'>

              {
  (activityRelatedToList && activityRelatedToList.length) && (
    <fieldset className={(activityRelatedToList.length === 1) && 'full-width-wrapper'}>

      <legend>{t(`${translationPath}related-to`)}</legend>
      {

        activityRelatedToList.map((item) => (
          <DataFileAutocompleteComponent
            labelValue={t(`${translationPath}${item.relatedToName}`)}
            idRef='RelatedToRef'
            selectedValues={((item.relatedToName==='Lead') && selected.relatedLead ) ||
            ((item.relatedToName==='Contact') && selected.relatedContact ) || {}}



            multiple={false}

            data={((item.relatedToName==='Lead') && data.relatedLeads ) ||
            ((item.relatedToName==='Contact') && data.relatedContacts ) || []}

            displayLabel={(option) => {
              switch (item.relatedToName) {
                case 'Lead':
                  return (
                    (option.lead && option.lead.contact_name && option.lead.contact_name.name) ||
                    '');
                case 'Contact':
                  return (
                    (option.contact&& (option.contact.company_name? option.contact.company_name: `${option.contact.first_name} ${option.contact.last_name}`) ) ||
                    '');
              }
            }}

            renderFor={item.relatedToName&&item.relatedToName.toLowerCase()}
            withoutSearchButton
            isSubmitted={isSubmitted}

            helperText={((item.relatedToName==='Lead') 
            && getErrorByName(schema, 'relatedLeadNumberId' ).message ) ||
            ((item.relatedToName==='Contact') 
            && getErrorByName(schema, 'relatedContactNumberId' ).message ) || ''}

            error={((item.relatedToName==='Lead') 
            && getErrorByName(schema, 'relatedLeadNumberId' ).error ) ||
            ((item.relatedToName==='Contact') 
            && getErrorByName(schema, 'relatedContactNumberId' ).error ) || ''}

            isLoading={((item.relatedToName==='Lead') && loadings.relatedLeads ) ||
              ((item.relatedToName==='Contact') && loadings.relatedContacts ) || false
            }


            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}

            onChange={(event, newValue) => relatedToChangeHandler(item,newValue)}
          />
        ))
      }
    </fieldset>
  ) || null

                }
              </div>
              <div className='dialog-content-item'>
                <DatePickerComponent
                  idRef='activityDateRef'
                  labelClasses='Requierd-Color'
                  labelValue='activity-date'
                  placeholder='DD/MM/YYYY'
                  value={state.activityDate ? state.activityDate : moment().format('YYYY-MM-DDTHH:mm:ss')}
                  helperText={getErrorByName(schema, 'activityDate').message}
                  minDate={!activeItem ? moment().format('YYYY-MM-DDTHH:mm:ss') : (activeItem && activeItem.createdOn && moment(activeItem.createdOn).format('YYYY-MM-DDTHH:mm:ss')) || ''}
                  error={getErrorByName(schema, 'activityDate').error}
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onDateChanged={(newValue) => {
                    setState({
                      id: 'activityDate',
                      value: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                    });
                    if (activeItem) activityTimeChangeHandler((newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null)
                  }}
                />
                </div>
              <div className='dialog-content-item'>
                <DatePickerComponent
                  idRef='activityDateRef'
                  labelValue='activity-time'
                  labelClasses='Requierd-Color'
                  isTimePicker
                  value={state.activityDate ? state.activityDate : moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ss')}
                  helperText={getErrorByName(schema, 'activityDate').message}
                  error={getErrorByName(schema, 'activityDate').error}
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onDateChanged={(newValue) => {
                    setState({
                      id: 'activityDate',
                      value: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                    });
                    if (activeItem) activityTimeChangeHandler((newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null)

                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs 
                  idRef='stageRef'
                  labelValue='stage'
                  value={
                    (selected.activityType && selected.activityType.leadStageName) ||
                    t(`${translationPath}not-contacted`)
                  }
                  isDisabled
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                // onInputChanged={(event) => {
                //   setState({ id: 'stage', value: event.target.value });
                // }}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='subjectRef'
                  labelValue='subject'
                  value={state.subject || ''}
                  helperText={getErrorByName(schema, 'subject').message}
                  error={getErrorByName(schema, 'subject').error}
                  isWithError
                  isDisabled
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setState({ id: 'subject', value: event.target.value });
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <SwitchComponent
                  idRef='isOpenStatusRef'
                  isChecked={state.isOpen}
                  themeClass='theme-line'
                  labelValue={(state.isOpen && 'open') || 'closed'}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(event, isChecked) =>
                    setState({ id: 'isOpen', value: isChecked })}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='created-byRef'
                  labelValue='created-by'
                  value={
                    (state.createdByName || '')
                  }
                  isDisabled
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              <div className='dialog-content-item w-100'>
                <Inputs
                  idRef='commentsRef'
                  labelValue='comments'
                  value={state.comments || ''}
                  helperText={getErrorByName(schema, 'comments').message}
                  error={getErrorByName(schema, 'comments').error}
                  isWithError
                  isSubmitted={isSubmitted}
                  multiline
                  rows={4}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setState({ id: 'comments', value: event.target.value });
                  }}
                />
              </div>
              {reminder ? (
                <div className='reminder-wrapper'>
                  {state.activityReminders &&
                    state.activityReminders.map((item, index) => (
                      <div className='reminder-item-wrapper' key={`remindersRef${index + 1}`}>
                        <div className='mb-1 w-100 px-2'>
                          <span>{`${t(`${translationPath}reminder`)}# ${index + 1}`}</span>
                        </div>
                        <div className='reminder-section'>
                          <SelectComponet
                            idRef={`reminderWayRef${index + 1}`}
                            data={Object.values(ReminderTypesEnum)}
                            value={item.reminderType}
                            valueInput='key'
                            textInput='value'
                            onSelectChanged={(value) => {
                              item.reminderType = +(value || 1);
                              setState({ id: 'activityReminders', value: state.activityReminders });
                            }}
                            translationPathForData={translationPath}
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                          />
                        </div>
                        <div className='reminder-section'>
                          <SelectComponet
                            idRef={`reminderPersonRef${index + 1}`}
                            data={selected.reminderPersons}
                            value={item.contactId || item.usersId || ''}
                            placeholder='reminder-for'
                            valueInput='id'
                            textInput='value'
                            helperText={
                              getErrorByName(
                                schema,
                                `activityReminders.${index}`,
                                'state.userNotSet'
                              ).message
                            }
                            error={
                              getErrorByName(
                                schema,
                                `activityReminders.${index}`,
                                'state.userNotSet'
                              ).error
                            }
                            isSubmitted={isSubmitted}
                            onSelectChanged={(value) => {
                              if (value) {
                                const localReminderPerson = selected.reminderPersons.find(
                                  (element) => element.id === value
                                );

                                if (localReminderPerson.type === 'contact') {
                                  item.contactId = value;
                                  item.usersId = null;
                                } else {
                                  item.usersId = value;
                                  item.contactId = null;
                                }
                              } else {
                                item.contactId = null;
                                item.usersId = null;
                              }
                              setState({ id: 'activityReminders', value: state.activityReminders });
                            }}
                            emptyItem={{
                              value: '',
                              text: 'select-reminder-for',
                              isDisabled: false,
                            }}
                            translationPathForData={translationPath}
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                          />
                        </div>
                        <div className='reminder-section'>
                          <div className='d-flex w-100'>
                            <Inputs
                              idRef='reminderTimeRef'
                              value={(item.reminderTime && getTimeValue(item)) || 0}
                              helperText={
                                getErrorByName(
                                  schema,
                                  `activityReminders.${index}`,
                                  'state.timeIsZero'
                                ).message
                              }
                              error={
                                getErrorByName(
                                  schema,
                                  `activityReminders.${index}`,
                                  'state.timeIsZero'
                                ).error
                              }
                              isDisabled={!state.activityDate || !state.assignAgentId}
                              wrapperClasses='mb-0'
                              endAdornment={(
                                <SelectComponet
                                  data={reminderAmountOfTimeTypes}
                                  value={item.reminderAmountOfTimeType || 1}
                                  valueInput='key'
                                  textInput='value'
                                  onSelectChanged={(value) => {
                                    item.reminderAmountOfTimeType = +(value || 1);
                                    reminderTimeCalculateHandler(
                                      item,
                                      (item.reminderTime && getTimeValue(item)) || 0,
                                      +(value || 1)
                                    );
                                    setState({
                                      id: 'activityReminders',
                                      value: state.activityReminders,
                                    });
                                  }}
                                  wrapperClasses='over-input-select w-auto'
                                  idRef='timeAmountTypeRef'
                                  parentTranslationPath={parentTranslationPath}
                                  translationPath={translationPath}
                                  translationPathForData={translationPath}
                                />
                              )}
                              min={0}
                              isWithError
                              isSubmitted={isSubmitted}
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                              onInputChanged={reminderTimeChangeHandler(item)}
                            />
                            {index > 0 && (
                              <ButtonBase
                                className='btns-icon theme-solid bg-secondary-light mt-1 mx-2'
                                onClick={reminderDecrementHandler(index)}
                              >
                                <span className='mdi mdi-minus c-black-light' />
                              </ButtonBase>
                            )}
                            {index === 0 && (
                              <ButtonBase
                                className='btns-icon theme-solid bg-secondary-light mt-1 mx-2'
                                disabled={!state.activityDate || !state.assignAgentId}
                                onClick={reminderIncrementHandler(index)}
                              >
                                <span className='mdi mdi-plus c-black-light' />
                              </ButtonBase>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : null}
          {data.notifications&&
            <ButtonBase onClick={() => { 
               setIsNotificationDialogOpen(true);
                }} className='btns theme-solid bg-cancel '>
                <span className='mdi mdi-bell c-warning px-2 ' />
                {' '}
                {t(`${translationPath}view-notifications`)}
              </ButtonBase>
            }
              <ButtonBase onClick={() => { setIsOpenGallery(true); setIsFilesDialogOpen(true); }} className='btns theme-solid bg-cancel '>
                <span className='mdi mdi-file-multiple c-warning px-2 ' />
                {' '}
                {GlobalTranslate.t('Shared:Files')}
              </ButtonBase>
              {uploadedFile&&uploadedFile.map((element, index) => (
                <Chip
                  className='uploader-chip'
                  label={element.fileName}
                  key={`importFileRefchip${index + 1}`}
                  onDelete={(!(element.isNotUploaded))&&(fileDeleted(element, index) || undefined)}
                  onClick={download(element)}
                  clickable
                />
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonBase onClick={() => { setIsOpenGallery(true); close(); }} className='btns theme-solid bg-cancel'>
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase className='btns theme-solid' type='submit'>
              {t(`${translationPath}${(activeItem && 'edit-activity') || 'add-activity'}`)}
            </ButtonBase>
          </DialogActions>
          <DialogComponent
            disableBackdropClick
            isOpen={isFilesDialogOpen}
            translationPath={translationPath}
            parentTranslationPath='Shared'
            titleClasses='DialogComponent-WorkingHoursDialogView'
            wrapperClasses='wrapperClasses-WorkingHoursDialogView'
            titleText='Activities-Files'
            onCloseClicked={() => setIsFilesDialogOpen(false)}
            maxWidth='sm'
            dialogContent={(
              <>
                <div className='dialog-content-item w-100'>
                  <UploaderActivitieFileComponent
                    onCloseClicked={() => { setIsOpenGallery(true);
                       setIsFilesDialogOpen(false); }}
                    onUploaderActivitieChange={(item) => {


                      convertToUploderReview(item);
                      setState({ id: 'fileId', value: item[0].uuid });
                      setState({ id: 'fileName', value: item[0].fileName });
                    }}
                    uploadedFile={uploadedFile}

                  />
                </div>
              </>
            )}
          />
        </form>
        <ActivityNotificationsDialog
          isOpen={isNotificationDialogOpen}
          setIsOpen={(value)=>{
            setIsNotificationDialogOpen(value);
          }}
          notifications={data.notifications}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />

      </Dialog>
    </div>
  );
};
ActivitiesManagementDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
};
ActivitiesManagementDialog.defaultProps = {
  activeItem: null,
  parentTranslationPath: '',
  translationPath: '',
};
