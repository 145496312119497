
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import {
  TabsComponent,
  RadiosGroupComponent,
  Spinner
} from '../../../../Components';
import { GetParams } from '../../../../Helper';
import { DeveloperVerticalTabsData  }  from './Sections/DevelopersVerticalTabsData' ; 

const parentTranslationPath = 'Developers';
const translationPath = '';

export const DevelopersProfileManagementView = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [viewType, setViewType] = useState(1);
  const activeItem = useSelector((state) => state.ActiveItemReducer);

  

  const [filterBy, setFilterBy] = useState({
    id: null,
    formType: null,
  });

  const [formType, setformType] = useState(+GetParams('formType'));

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };

  useEffect(() => {
    setFilterBy({
      formType: (GetParams('formType') && +GetParams('formType')) || null,
      id: (GetParams('id') && +GetParams('id')) || null,
    });
  }, []);

  return (
    <div className='developer-profile-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='d-flex-v-center'>
        <RadiosGroupComponent
          idRef='viewDataRef'
          data={[
            {
              key: 1,
              value: 'all-data',
            },
            {
              key: 2,
              value: 'missing-data',
            },
          ]}
          value={viewType}
          labelValue='view'
          labelInput='value'
          valueInput='key'
          themeClass='theme-line'
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          onSelectedRadioChanged={onViewTypeChangedHandler}
        />
      </div>
      <TabsComponent
        data ={DeveloperVerticalTabsData}
        labelInput='label'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-solid'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          id: filterBy.id,
          viewType,
          parentTranslationPath,
          translationPath,
          setActiveTab, 
          activeTab,
          typeOf:'1' , 
          taskRelatedTo : 'Contact', 
          formType : 'contact ' , 
        }}
      />
    </div>
  );
};