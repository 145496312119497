import React, { useCallback, useReducer, useRef } from "react";
import "./FormTablesGenerator.scss";
import { PropTypes } from 'prop-types';
import {
  AutocompleteComponent,
  CheckboxesComponent,
  Inputs,
  RadiosGroupComponent,
} from "../../../../../../../../../../../Components";
import { ButtonBase } from "@material-ui/core";
import { DynamicTableComponent } from "../DynamicTableComponent/DynamicTableComponent";
import { showWarn } from "../../../../../../../../../../../Helper";
import moment from "moment";

export const FormTablesGenerator = ({
  formMap,
  formText,
  optionsData,
  selected,
  onSelectedChange,
  getLookupsByName,
  getContactOptions,
  classes,
  isReadOnly
}) => {
  const defaultColNo = 2;

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [currentNewRow, setCurrentNewRow] = useReducer(reducer, {
    companyPartnerNamesAndNationalities: {},
    companyStakeholdersInformation: {},
    companySignatories: {},
  });

  const onCurrentNewRowChanged = ({ key, selectValue, isContactOptions }, tableKey) => {
    if(isContactOptions) {
      const autofilledRow = {
        ...(currentNewRow[tableKey] || {}),
        contactName: selectValue,
        contactId: selectValue?.contactsId,
        passportNumber: selectValue?.contact?.passport_no || null,
        nationality: selectValue?.contact?.nationality?.lookupItemName || null,
        contactMobile: selectValue?.contact?.mobile?.phone || null,
      }
      setCurrentNewRow({ id: tableKey, value: autofilledRow });
    }
    else {
      const rowWithChanges = {
        ...(currentNewRow[tableKey] || {}),
        [key]: selectValue,
      }
      setCurrentNewRow({ id: tableKey, value: rowWithChanges });
    }
  }

  const addRowHandler = (formSection) => {
    const addedRecord = currentNewRow[formSection.tableDataKey];
    const isEmptyRecord = !addedRecord?.contactName;

    if(isEmptyRecord) {
      showWarn("Please select a contact and fill record fields .");
      return; 
    }
    
    let localNewTableData = [...(selected[formSection.tableDataKey] || [])];
      localNewTableData.push(addedRecord);
      onSelectedChange(formSection.tableDataKey, localNewTableData);
      setCurrentNewRow({ id: formSection.tableDataKey, value: {} });
  };

  const getParsedValue = (value) => {
    let parsedValue = null;
    try {
      parsedValue = JSON.parse(value);
    } catch (_) {
      parsedValue = value;
    }
    return parsedValue;
  };

  const getYesNoValue = (val) => {
    if (typeof val === "boolean" && val == true)
      return "Yes"
    else if (typeof val === "boolean" && val == false)
      return "No"
  }

  const getDateValue = (isDate, val) => {
    if (isDate) return val? moment(val).format("LL") : null;
  }
  
  const returnFieldInput = (field) => {
    switch (field.fieldType) {
      case "textInput":
        return (
          <Inputs
            idRef={`${field.input}Ref`}
            inputPlaceholder="enter"
            value={selected[field.input]}
            isDisabled={field.disabled}
            type={field.isNumber? "number" : "text"}
            onInputChanged={(event) => onSelectedChange(field.input, event.target.value)}
          />
        );
      case "selectInput":
        return (
          <AutocompleteComponent
            idRef={`${field.input}Ref`}
            inputPlaceholder={`select`}
            wrapperClasses="w-min-unset"
            selectedValues={selected[field.input] || null}
            data={optionsData[field.optionsKey] || []}
            displayLabel={(option) => option.lookupItemName || ""}
            multiple={false}
            isDisabled={field.disabled}
            withoutSearchButton
            onOpen={() => {
              if (
                optionsData[field.optionsKey] &&
                optionsData[field.optionsKey].length == 0
              ) getLookupsByName(field.lookupName, field.optionsKey);
            }}
            onChange={(_, newValue) => onSelectedChange(field.input, newValue)}
          />
        );
      case "groupCheckbox":
        return (
          <CheckboxesComponent
            idRef={`${field.input}Ref`}
            data={selected[field.input] || []}
            isRow
            onSelectedCheckboxChanged={(clickedOption) => {
              let localCheckedOptions = [...(selected[field.input] || [])];
              const checkedOptionIndex = localCheckedOptions.findIndex(
                (item) => clickedOption.key === item.key
              );
              const changedClickedOption = {
                ...clickedOption,
                value: !clickedOption.value,
              };
              if (checkedOptionIndex !== -1) {
                localCheckedOptions.splice(
                  checkedOptionIndex,
                  1,
                  changedClickedOption
                );
              }
              onSelectedChange(field.input, localCheckedOptions);
            }}
            checked={(clickedOption) => clickedOption.value}
            labelValue=""
            labelInput="label"
          />
        );
      case "singleCheckbox":
        return (
          <CheckboxesComponent
          idRef={`${field.input}Ref`}
          label={field.boxLabel || ''}
          singleChecked={null}
          onSelectedCheckboxClicked={()=>{}}
        />
        );
      case "radioButton":
        return (
          <RadiosGroupComponent
            idRef={`${field.input}Ref`}
            data={optionsData[field.optionsKey] || []}
            valueInput="value"
            labelInput="key"
            value={selected[field.input]}
            onSelectedRadioChanged={(event) =>
              onSelectedChange(field.input, getParsedValue(event.target.value))
            }
            isDisabled={field.disabled}
          />
        );
      case "readOnly":
        return (
          <span className="readOnly_input">
            {selected[field.input] &&
              typeof selected[field.input] === "object"
              ? selected[field.input].lookupItemName || ""
              : (getYesNoValue(selected[field.input]) ||
              // getDateValue(field.isDate, selected[field.input]) ||
              selected[field.input] ||
              "")}
          </span>
        );
      case "signature":
        return (
          <span className="signature_input">{field.placeholder || ""}</span>
        );
      default:
        return null;
    }
  };

  const returnReadOnlyFieldInput = (field) => {
    switch (field.fieldType) {
      case "groupCheckbox":
        return (
          <CheckboxesComponent
            idRef={`${field.input}Ref`}
            data={selected[field.input] || []}
            isRow
            onSelectedCheckboxChanged={(clickedOption) => {
              let localCheckedOptions = [...(selected[field.input] || [])];
              const checkedOptionIndex = localCheckedOptions.findIndex(
                (item) => clickedOption.key === item.key
              );
              const changedClickedOption = {
                ...clickedOption,
                value: !clickedOption.value,
              };
              if (checkedOptionIndex !== -1) {
                localCheckedOptions.splice(
                  checkedOptionIndex,
                  1,
                  changedClickedOption
                );
              }
              onSelectedChange(field.input, localCheckedOptions);
            }}
            checked={(clickedOption) => clickedOption.value}
            labelValue=""
            labelInput="label"
          />
        );
      case "singleCheckbox":
        return (
          <CheckboxesComponent
            idRef={`${field.input}Ref`}
            label={field.boxLabel || ""}
            singleChecked={null}
            onSelectedCheckboxClicked={() => {}}
          />
        );
      case "readOnly":
        return (
          <span className="readOnly_input">
            {selected[field.input] &&
              typeof selected[field.input] === "object"
              ? selected[field.input].lookupItemName || ""
              : (getYesNoValue(selected[field.input]) ||
              // getDateValue(field.isDate, selected[field.input]) ||
              selected[field.input] ||
              "")}
          </span>
        );
      case "signature":
        return (
          <div className="signature_input">
            <canvas className="signature-pad" width="500" height="120"></canvas>
            <ButtonBase className="signature_button">Clear</ButtonBase>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div  className={`KycForm ${classes || ""}`}>
      {Object.values(formMap).map((formSection) => (
        <table className="Form_Table" key={formSection.sectionLabel || ""}>
          <thead className="Spaced_Table_Header Space_After">
            <tr>
              <th>
                <span>
                  <span className="Header_Title">
                    {formSection.sectionLabel || ""}
                  </span>
                  <span className="Header_Sub_Title">
                    {formSection.sectionSubLabel || ""}
                  </span>
                </span>
                {formSection.canAddRecord ? (
                  <ButtonBase
                    className="Header_Button"
                    onClick={() => addRowHandler(formSection)}
                  >
                    <span className="mdi mdi-plus-circle-outline"></span>
                    <span>Add Record</span>
                  </ButtonBase>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            <table
              className={`Nested_Table  ${
                formSection.sectionColNo === defaultColNo ? "tow-col-table" : ""
              }`}
            >
              <tbody>
                {formSection.beforeText ? (
                  <tr>
                    <td
                      className="Row_Text"
                      colspan={`${formSection.sectionColNo || defaultColNo}`}
                    >
                      <p>{formText[formSection.sectionKey]? formText[formSection.sectionKey]?.beforeText : "" }</p>
                    </td>
                  </tr>
                ) : null}
                {formSection.sectionFields
                  ? formSection.sectionFields.map((field, fieldIndex) => {
                      const numberOfColumns =
                        formSection.sectionColNo || defaultColNo;
                      const firstFieldIndex =
                        fieldIndex * (numberOfColumns / 2);
                      const secondFieldIndex =
                        fieldIndex * (numberOfColumns / 2) + 1;

                      return (
                        <tr key={`field-${field.input}`}>
                          {formSection.sectionFields[firstFieldIndex] ? (
                            <>
                              <td>
                                {formSection.sectionFields[firstFieldIndex]
                                  .label || ""}
                              </td>
                              <td>
                                <div className="Cell_Value">
                                  {isReadOnly
                                  ? returnReadOnlyFieldInput(formSection.sectionFields[firstFieldIndex])
                                  : returnFieldInput(
                                    formSection.sectionFields[firstFieldIndex]
                                  )}
                                </div>
                              </td>
                            </>
                          ) : null}
                          {formSection.sectionColNo > defaultColNo &&
                          formSection.sectionFields[secondFieldIndex] ? (
                            <>
                              <td>
                                {formSection.sectionFields[secondFieldIndex]
                                  .label || ""}
                              </td>
                              <td>
                                <div className="Cell_Value">
                                    {isReadOnly
                                      ? returnReadOnlyFieldInput(
                                        formSection.sectionFields[secondFieldIndex])
                                      : returnFieldInput(
                                        formSection.sectionFields[secondFieldIndex]
                                      )}
                                </div>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      );
                    })
                  : null}
                {formSection.canAddRecord ? (
                  <DynamicTableComponent
                    headerData={formSection.headerData || []}
                    data={selected[formSection.tableDataKey] || []}
                    tableKey={formSection.tableDataKey}
                    onCurrentNewRowChanged={(props) => onCurrentNewRowChanged(props, formSection.tableDataKey)}
                    currentNewRow={currentNewRow}
                    fieldProps={{
                      optionsData,
                      onSelectedChange,
                      getLookupsByName,
                      getContactOptions,
                    }}
                    isReadOnly={isReadOnly}
                  />
                ) : null}
                {formSection.afterText ? (
                  <tr>
                    <td
                      className="Row_Text"
                      colspan={`${formSection.sectionColNo || defaultColNo}`}
                    >
                      <p>{formText[formSection.sectionKey]? formText[formSection.sectionKey]?.afterText : "" }</p>
                      </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </tbody>
        </table>
      ))}
    </div>
  );
};

FormTablesGenerator.propTypes = {
  formMap: PropTypes.object,
  formText: PropTypes.object,
  optionsData: PropTypes.object,
  selected: PropTypes.object,
  onSelectedChange: PropTypes.func,
  getLookupsByName: PropTypes.func,
  getContactOptions: PropTypes.func,
  isReadOnly: PropTypes.bool,
}

FormTablesGenerator.defaultProps = {
    formMap:{},
    formText:{},
    optionsData:{},
    selected:{},
    onSelectedChange:()=>{},
    getLookupsByName:()=>{},
    getContactOptions:()=>{},
    isReadOnly: false,
};