import React from "react";
import { Box, Typography } from "@material-ui/core";
import { OverviewCard } from "../OverviewCard";
import { useTranslate } from "../../../../../../Hooks";

// Styles
import useStyles from "./styles";

// Icons
import {
  LinkedIn,
  Facebook,
  Twitter,
  Instagram,
  Snapchat,
  Tiktok,
} from "../../../../../../assets/icons";

function SocialProfiles({ contactDetails }) {
  const styles = useStyles();

  const { translate: sharedTranslate } = useTranslate("SharedV2");

  const linkedinProfile = contactDetails?.linkedin_link?.link;
  const facebookProfile = contactDetails?.facebook_link?.link;
  const twitterProfile = contactDetails?.twitter_link?.link;
  const instagramProfile = contactDetails?.instagram_link?.link;
  const snapchat_link = contactDetails?.snapchat_link;
  const tiktokProfile = contactDetails?.tiktok_link;

  const isEmptyState = !linkedinProfile && !facebookProfile && !twitterProfile && !instagramProfile && !snapchat_link && !tiktokProfile;

  return (
    <OverviewCard title="Social profiles">
      {!isEmptyState &&
        <Box className={styles.socialProfilesWrapper}>
        {!!linkedinProfile && (
          <Box className={styles.socialProfileItem}>
            <LinkedIn width="25" height="25" className={styles.socialIcon} />
            <Typography variant="body1" className={styles.socialProfileText}>
              LinkedIn/
            </Typography>
            <a
              href={linkedinProfile}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              {linkedinProfile}
            </a>
          </Box>
        )}
        {!!facebookProfile && (
          <Box className={styles.socialProfileItem}>
            <Facebook width="25" height="25" className={styles.socialIcon} />
            <Typography variant="body1" className={styles.socialProfileText}>
              Facebook/
            </Typography>
            <a
              href={facebookProfile}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              {facebookProfile}
            </a>
          </Box>
        )}
        {!!twitterProfile && (
          <Box className={styles.socialProfileItem}>
            <Twitter width="25" height="25" className={styles.socialIcon} />
            <Typography variant="body1" className={styles.socialProfileText}>
              X/
            </Typography>
            <a
              href={twitterProfile}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              {twitterProfile}
            </a>
          </Box>
        )}
        {!!instagramProfile && (
          <Box className={styles.socialProfileItem}>
            <Instagram width="25" height="25" className={styles.socialIcon} />
            <Typography variant="body1" className={styles.socialProfileText}>
              Instagram/
            </Typography>
            <a
              href={instagramProfile}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              {instagramProfile}
            </a>
          </Box>
        )}
        {!!snapchat_link && (
          <Box className={styles.socialProfileItem}>
            <Snapchat width="25" height="25" className={styles.socialIcon} />
            <Typography variant="body1" className={styles.socialProfileText}>
              Snapchat/
            </Typography>
            <a
              href={snapchat_link}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              {snapchat_link}
            </a>
          </Box>
        )}
        {!!tiktokProfile && (
          <Box className={styles.socialProfileItem}>
            <Tiktok width="25" height="25" className={styles.socialIcon} />
            <Typography variant="body1" className={styles.socialProfileText}>
              Tiktok/
            </Typography>
            <a
              href={tiktokProfile}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              {tiktokProfile}
            </a>
          </Box>
        )}
        </Box>
      }

      {isEmptyState && 
        <Typography className={styles.emptyState} variant="h6" align="center" color="textSecondary">
          {sharedTranslate("no-data-avlbl")}
        </Typography>
      }
    </OverviewCard>
  );
}

export default SocialProfiles;
