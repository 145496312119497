import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { Spinner, Tables } from '../../../../../Components';
import { GetParams } from '../../../../../Helper';
import { GetAllContactUnitTransactions } from '../../../../../Services';

export const DeveloperProfileProjectOverviewComponent = ({ 
  id,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [contactId, setContactId] = useState(+GetParams('id'));
  const [projectsOverview, setProjectsOverview] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
  });
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const getAllContactUnitTransactions = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllContactUnitTransactions(filter, contactId);
    if (!(res && res.status && res.status !== 200)) {
      setProjectsOverview({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setProjectsOverview({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter, contactId]);

  useEffect(() => {
    if (contactId) getAllContactUnitTransactions();
  }, [getAllContactUnitTransactions, contactId]);

  useEffect(() => {
    setContactId(+GetParams('id'));
  }, [location]);
  return (
    <div className='associated-contacts-wrapper childs-wrapper p-relative mt-2'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section'>
        <span>{t(`${translationPath}project-overview`)}</span>
      </div>
      <div className='w-100 px-2'>
        <div className='filter-sections-wrapper' />
        <Tables
          data={projectsOverview.result}
          headerData={[
            { id: 1, label: 'propertyId', input: 'propertyId' },
            {
              id: 2,
              label: 'property-name',
              input: 'property_name',
            },
            {
              id: 3,
              label: 'sub-community-name',
              input: 'sub_community_name',
            },
            {
              id: 5,
              label: 'start-date',
              input: 'start_date',
              isDate: true,
            },
            {
              id: 4,
              label: 'hand-over-date',
              input: 'hand_over_date',
              isDate: true,
            },
         
            {
              id: 5,
              isSortable: true,
              label: 'status',
              input: 'status',
              component: (item, index) => (
                (item.status && item.status === 'Ongoing' && <div className='globel-open-style' index={index}>{t(`${translationPath}ongoing`)}</div>) || <div className='globel-closed-style' index={index}>{t(`${translationPath}ongoing`)}</div>
              ),
            },
            {
              id:6,
              label:'number-of-agents',
              input:'number_of_agents'
            } ,
            {
              id:7,
              label:'total-number-of-units',
              input:'total_number_of_units'
            } ,
            {
              id:8,
              label:'number-of-sold-units',
              input:'number_of_sold_units'
            } , 
            {
              id:9,
              label:'number-of-remaining-units',
              input:'number_of_remaining_units'
            } ,
            {
              id:10,
              label:'transaction-value',
              input:'transaction_value'
            }


          ]}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          dateFormat='DD, MMM, YYYY'
          defaultActions={[]}
          // activePageChanged={activePageChanged}
          // p={itemsPerPageChanged}
          // actionsOptions={
          //   {
          //     //   onActionClicked: tableActionClicked,
          //   }
          // }
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={projectsOverview.totalCount}
        />
      </div>
    </div>
  );
};

DeveloperProfileProjectOverviewComponent.propTypes = {
  id: PropTypes.number.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
