import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import {
  ConvertJsonV2Component,
  Spinner,
  TabsComponent,
} from "../../../../Components" ; 
import {
  GetAllFormFieldTabsByFormId,
  UpdateInquiryServices,
} from "../../../../Services";
import {
  bottomBoxComponentUpdate,
  FormErrorsHandler,
  formItemsBuilder,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
} from "../../../../Helper";
import { LookupsRules } from "../../../../Rule";

export const DeveloperProfile = ({
  viewType,
  parentTranslationPath,
  translationPath,
  onSave,
  save,
  isDetailsDialog,
  isOpen,
  isOpenChanged,
  typeOf,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const location = useLocation();
  const [activeItem, setActiveItem] = useState({
    id: null,
    formType: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [formData, setFormData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const pathName = window.location.pathname
    .split("/home/")[1]
    .split("/view")[0]
    .split("/")[0];

  const [enumsInitFinished, setEnumsInitFinished] = useState(false);
  const [errors, setErrors] = useState([]);
  const [developerInitDetails, setDeveloperInitDetails] = useState({});
  const [developerDetails, setDeveloperDetails] = useState(null);
  const [formAndTabs, setFormAndTabs] = useState([]);
  const [developerDetailsObj, setDeveloperDetailsObj] = useState({developerJson :  {
    developer : {
      developer_name  : "ayat"
    }
  }});
  const onTabChanged = (e, newTap) => {
    setEnumsInitFinished(false);
    setActiveTab(newTap);
  };

  const getAllFormFieldTabsByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllFormFieldTabsByFormId({
      formId: (135)
    });
    if (!(result && result.status && result.status !== 200))
      setFormAndTabs(result[0] || []);
    else setFormAndTabs([]);
    setIsLoading(false);
  }, [activeItem]);

  const getDeveloperDetails = useCallback(async () => {
    setIsLoading(true);
   
    setIsLoading(false);
  }, [activeItem]);

  const dataHandler = useCallback(() => {
    if (formData.length === 0)
      setFormData(
        formItemsBuilder(
          developerDetails &&
          developerDetails.developerJson &&
          developerDetails.developerJson.developer,
          formAndTabs
        )
      );
  }, [developerDetails, formAndTabs, formData.length]);

  const cancelHandler = () => {
    GlobalHistory.goBack();
  };


  const saveHandler = async () => {
    setIsSubmitted(true);
    if (errors && errors.length > 0) {
      setEnumsInitFinished(false) ; 
      const firstErrorTapIndex =
        formData &&
        formData.findIndex(
          (item) =>
            item.findIndex((element) => element.field.id === errors[0].key) !==
            -1
        );
      if (firstErrorTapIndex !== -1) setActiveTab(firstErrorTapIndex);
      showError(errors[0].message);
      return;
    }

    let body = {
      developerJson:
        developerDetailsObj &&
        developerDetailsObj.developerJson &&
        JSON.stringify(developerDetailsObj.developerJson),
    };
    setIsLoading(true);

    const putResponse = await UpdateInquiryServices(
      activeItem && activeItem.id,
      body
    );
    if (!(putResponse && putResponse.status && putResponse.status !== 200)) {
     
      showSuccess(t(`${translationPath}edit-developer-successfully`));
      setIsLoading(false);
      if (isOpenChanged) {
        isOpenChanged();
      }
    } else {
      setIsLoading(false);
      showError(t(`${translationPath}edit-developer-failed`));
    }
  };
  const onLoadingsChanged = (value, key) => {
    setLoadings((items) => {
      const itemIndex = items.findIndex((item) => item.key === key);
      if (value) {
        const addItem = {
          key,
          value,
        };
        if (itemIndex !== -1) items[itemIndex] = addItem;
        else items.push(addItem);
      } else if (itemIndex !== -1) items.splice(itemIndex, 1);
      return [...items];
    });
  };

  const onItemChanged =
    (item, index) => (newValue, itemIndex, itemKey, parentItemKey) => {
      setFormData((elements) => {
        if (parentItemKey) {
          if (itemIndex !== undefined)
            elements[activeTab][itemIndex][parentItemKey][itemKey] = newValue;
          else elements[activeTab][index][parentItemKey][itemKey] = newValue;
        } else if (itemIndex)
          elements[activeTab][itemIndex][itemKey] = newValue;
        else elements[activeTab][index][itemKey] = newValue;
        return [...elements];
      });
    };

  const onValueChanged = (item) => (newValue, itemIndex, id) => {
    setDeveloperDetailsObj((items) => {
      if (items && items.developerJson && items.developerJson.developer) {
        if ((itemIndex || itemIndex === 0) && itemIndex !== -1) {
          if (
            id ||
            (formData[activeTab][itemIndex] &&
              formData[activeTab][itemIndex].field &&
              formData[activeTab][itemIndex].field.id)
          )
            items.developerJson.developer[
              id || formData[activeTab][itemIndex].field.id
            ] = newValue;
        } else if (item && item.field && item.field.id)
          items.developerJson.developer[item.field.id] = newValue;
        return { ...items };
      }
    });
  };

  const lookupInit = useCallback(() => {
    setIsLoading(true);
    const result = LookupsRules(
      formData[activeTab],
      developerDetailsObj &&
      developerDetailsObj.developerJson &&
      developerDetailsObj.developerJson.developer,
      onLoadingsChanged
    );
    setFormData((items) => {
      items.splice(activeTab, 1, result);
      return [...items];
    });
    setIsLoading(false);
  }, [activeTab, developerDetailsObj, formData]);

  useEffect(() => {
    if (formData.length > 0 && developerDetailsObj)
      setErrors(
        FormErrorsHandler(
          formData,
          developerDetailsObj &&
          developerDetailsObj.developerJson &&
          developerDetailsObj.developerJson.developer
        )
      );
  }, [developerDetailsObj, formData]);

  useEffect(() => {
    if (!enumsInitFinished && formData.length > 0) {
      setEnumsInitFinished(true);
      lookupInit();
    }
  }, [activeTab, enumsInitFinished, formData.length, lookupInit]);
  useEffect(() => {
    if (
      developerDetailsObj &&
      developerDetailsObj.developerJson &&
      developerDetailsObj.developerJson.developer
    )
      dataHandler();
  }, [developerDetailsObj, dataHandler]);
  useEffect(() => {
    if (formAndTabs.length > 0 && activeItem.id)
      getDeveloperDetails() ;
  }, [formAndTabs, getDeveloperDetails]);
  useEffect(() => {
   getAllFormFieldTabsByFormId();
  }, [activeItem, getAllFormFieldTabsByFormId]);

  // useEffect(() => {
  //   if (isDetailsDialog) {
  //     const obj = JSON.parse(localStorage.getItem("current"));
  //     setActiveItem({
  //       id: obj.id,
  //     });
  //   } else {
  //     setActiveItem({
  //       id: +GetParams("id"),
  //       formType: +GetParams("formType"),
  //     });
  //   }
  // }, [location]);

  useEffect(() => {
    if (developerDetails) {
      const devObj = {
        ...developerDetails,
        developerJson:
        developerDetails &&
        developerDetails.developerJson &&
          JSON.parse(developerDetails.developerJson),
      };
      setDeveloperDetailsObj(devObj);
    }
  }, [developerDetails]);

  useEffect(() => {
    if (!isOpen) {
      bottomBoxComponentUpdate(
        <div className="d-flex-v-center-h-end flex-wrap">
          <ButtonBase
            className="btns theme-transparent mb-2"
            onClick={cancelHandler}
          >
            <span>{t("Shared:cancel")}</span>
          </ButtonBase>

          <ButtonBase
            className="btns theme-solid mb-2"
            onClick={saveHandler}
          >
            <span>{t("Shared:save")}</span>
          </ButtonBase>
        </div>
      );
    }
  });

  return (
    <div className="contacts-information-wrapper childs-wrapper b-0">
      <Spinner isActive={isLoading} isAbsolute />
      <TabsComponent
        data={formAndTabs}
        labelInput="tab"
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses="theme-curved"
        currentTab={activeTab}
        onTabChanged={onTabChanged}
      />
      <div className="tabs-content-wrapper">
        {formData &&
          formData[activeTab] &&
          formData[activeTab]
            .filter(
              (item) =>
                viewType === 1 )
            .map((item, index) => (
              <ConvertJsonV2Component
                key={`form${index + 1}-${activeTab}`}
                item={item}
                allItems={formData[activeTab]}
                allItemsValues={
                  developerDetailsObj &&
                  developerDetailsObj.developerJson &&
                  developerDetailsObj.developerJson.developer
                }
                itemValue={
                  developerDetailsObj &&
                  developerDetailsObj.developerJson &&
                  developerDetailsObj.developerJson.developer &&
                  developerDetailsObj.developerJson.developer[item.field.id] === 0
                    ? "0"
                    : developerDetailsObj &&
                    developerDetailsObj.developerJson &&
                    developerDetailsObj.developerJson.developer &&
                    developerDetailsObj.developerJson.developer[item.field.id]
                }
                isSubmitted={isSubmitted}
                onItemChanged={onItemChanged(item, index)}
                onValueChanged={onValueChanged(item, index)}
                helperText={
                  (errors.find((element) => element.key === item.field.id) &&
                    errors.find((element) => element.key === item.field.id)
                      .message) ||
                  ""
                }
                error={
                  errors.findIndex(
                    (element) => element.key === item.field.id
                  ) !== -1
                }
                isLoading={
                  loadings.findIndex(
                    (element) => element.key === item.field.id && element.value
                  ) !== -1
                }
                onLoadingsChanged={onLoadingsChanged}
                typeOf={typeOf}
              />
            ))}
      </div>
    </div>
  );
};

DeveloperProfile.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
 
};

DeveloperProfile.defaultProps = {
};
