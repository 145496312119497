
  
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {  useDispatch } from 'react-redux';
import editIcon from '../../../../assets/images/icons/edit-pen.png';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { GlobalHistory } from '../../../../Helper';
import { PopoverComponent } from '../../../../Components' ; 
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

export const DevelopersCards = ({
  data,
  translationPath,
  parentTranslationPath,
  setFilter,
  isLoading,
}) => {

  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const [selectedPopoverAction, setSelectedPopoverAction] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const handlePopoverOpen = useCallback((event, item) => 
   {
    setActiveItem(item);
    dispatch(ActiveItemActions.activeItemRequest(item));
    event.stopPropagation();
    event.preventDefault();
    setSelectedPopoverAction(event.currentTarget);
  }, []);

  const handlePopoverClose = () => {
    setSelectedPopoverAction(null);
  }

  const editClickHandler = () => {
      GlobalHistory.push(`/home/zero-matching-sale/zero-matching-sale-profile-edit?formType=${44}&id=${activeItem && activeItem?.inquiryId}&activeFormType=${1}`);
    handlePopoverClose();

  };

  const deleteClickHandler = () => {
    handlePopoverClose();
  };

  const cardOnClick = (item) => {
    setActiveItem(item);
  };


  const cancelClickHandler = () => {
    handlePopoverClose();

  }


  return (
    <div className='Inquiries-card-component-wrapper'>
      {data &&
        data.map((item, index) => (
          <div className='Inquiries-card-wrapper' key={`inquiryCardRef${index + 1}`} onClick={() => {
            if (selectedPopoverAction == null) cardOnClick(item)
          }
          }>
            <div
              className={`cards-wrapper ${'0' === index ? 'active' : ''} `}
            >
              <div className='cards-header'>
                <span className='mdi mdi-dots-vertical' style={{ position: 'absolute' }} onClick={(event) => handlePopoverOpen(event, item)}></span>

                <div className='headerItem1'>
                  <div className='inquiries-id-part'>
                    {t(`${translationPath}developerId`)}

                    {' '}
                    {' '}
                    {' '}
                    {' '}
                    {' '}
                    {' '}
                    :
                    {((item?.developerId) || '')}
                  </div>
                </div>
              </div>

              <div className='cards-body'>
                <div className='body-item'>
                  <span>
                    {t(`${translationPath}inquiry-type`)}
                  </span>
                  <span className='item-value'>
                    
                  </span>

                </div>
                <div className='body-item'>
                  <span>
                    {t(`${translationPath}status`)}
                  </span>
                  <span
                    className={`status${item.inquiryStatus === 'Canceled' ? ' cancel' : ''}`}>
                    {item.inquiryStatus} </span>

                </div>
                {

                  item.leadId && (
                    <div className='body-item'>
                      <span>
                        {t(`${translationPath}leadId`)}
                      </span>
                      <span className='item-value'>
                        <span className='leadId-part'>
                          {(
                            item && item.leadId && (
                              <CopyToClipboardComponents
                                data={item.leadId}
                                childrenData={item.leadId}
                                CustomizationClassName='block-container low-space'
                              />
                            )) ||
                            'N/A'}
                          <span>

                          </span>
                        </span>
                      </span>

                    </div>

                  )
                }

                    <PopoverComponent
                      idRef={`productRelease${item?.inquiryId}Ref`}
                      attachedWith={selectedPopoverAction}
                      popoverClasses='release-note-popover'
                      handleClose={() => handlePopoverClose()}
                      component={
                        <>
                            <div className='popover-item' onClick={() => editClickHandler()}>
                              <img src={editIcon} />
                              <span>Edit</span>
                            </div>
                        </>
                      }
                    />
              </div>
            </div>
          </div>

        ))}
    </div>)

}


  