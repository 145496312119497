import { DeveloperProfile , AgreementsAndComplianceView   , PeopleAndLocationsView , SocialMediaLink  , DeveloperProfileLeadsComponent , DeveloperProfileUnitsComponent  , DeveloperProfileTransactionsComponent , DeveloperProfileProjectOverviewComponent } from '../../DeveloperTabsSections' ;
import { DeveloperProfileActivitiesComponent } from '../../DeveloperTabsSections/DeveloperProfileActivitiesComponent'; 
import { ProjectTasksView } from '../../../TaskConfiguration/ProjectTasks/Utilities/ProjectTasksView/ProjectTasksView' ; 

export const DeveloperVerticalTabsData = [
 {
   label: 'developer-profile',
   component: DeveloperProfile ,
 },
 {
   label: 'agreements-and-compliance',
   component: AgreementsAndComplianceView ,

 },
 
 {
   label: 'people-and-locations',
   component: PeopleAndLocationsView ,
     
 },
 {
   label: 'social-media-link',
   component: SocialMediaLink , 
 },
 {
  label: "tasks",
  component: ProjectTasksView,
},
 {
  label: "leads",
  component: DeveloperProfileLeadsComponent,
},
{
  label: "units",
  component: DeveloperProfileUnitsComponent,
},
{
  label: "activities",
  component: DeveloperProfileActivitiesComponent,
},

{
  label: "transactions-history",
  component: DeveloperProfileTransactionsComponent,
},
{
  label: "project-overview",
 component: DeveloperProfileProjectOverviewComponent,
},

] ;



