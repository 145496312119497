import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  useIsDesktop,
  useMainContainerPadding,
  useQuery,
  useSelectedTheme,
  useTitle,
  useTranslate,
} from "../../../../Hooks";
import {
  CustomButton,
  PageTitle,
  CustomTabs,
  CustomBreadCrumb,
  CustomBadge,
  ContactProfileOverviewSkeleton,
  SocialProfilesSkeleton,
} from "../../../../Components";
import {
  AssignedAgents,
  AssociatedContacts,
  ContactActivities,
  ContactDetails,
  ContactDocuments,
  ContactDuplicates,
  ContactLeads,
  ContactOverviewSummary,
  ContactProfileOverview,
  ContactTasks,
  ContactUnits,
  SocialProfiles,
  TransactionHistory,
} from "./UI";
import { useVerticalNav } from "../../../../Contexts/VerticalNavContext";
import { GetAdvanceSearchContacts } from "../../../../Services";
import { ContactsMapper } from "../../ContactsView";

// Styles
import useStyles from "./styles";

// Icons
import { ClockIcon, EditIcon } from "../../../../assets/icons";

function ContactOverview() {
  const { isExpanded } = useVerticalNav();

  const styles = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const [contactDetails, setContactDetails] = useState({
    result: {},
    totalCount: 0,
  });
  const [isContactDetailsLoading, setIsContactDetailsLoading] = useState(true);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const query = useQuery();
  const contactId = query.get("id");

  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  useTitle(translate("contact-overview-page-title"));

  const { isDesktop } = useIsDesktop();

  useMainContainerPadding("16px 32px", "32px");

  const contactOverviewTabs = [
    {
      label: "Overview",
      component: (
        <ContactOverviewSummary
          setTabValue={setTabValue}
          contactId={contactId}
        />
      ),
    },
    {
      label: "Contact details",
      component: <ContactDetails />,
    },
    {
      label: "Associated contacts",
      component: <AssociatedContacts />,
    },
    {
      label: "Leads",
      component: <ContactLeads />,
    },
    {
      label: "Units",
      component: <ContactUnits />,
    },
    {
      label: "Activities",
      component: <ContactActivities />,
    },
    {
      label: "Tasks",
      component: <ContactTasks />,
    },
    {
      label: "Transactions history",
      component: <TransactionHistory />,
    },
    {
      label: "Assigned agents",
      component: <AssignedAgents />,
    },
    {
      label: "Documents",
      component: <ContactDocuments />,
    },
    {
      label: "Duplicates",
      component: <ContactDuplicates />,
    },
  ];

  const getContactDetails = useCallback(async () => {
    setIsContactDetailsLoading(true);

    try {
      const isForLog = true;

      let body = {
        criteria: {
          Ids: [{ searchType: 1, value: contactId }],
        },
        filterBy: "createdOn",
        orderBy: 2,
      };

      const res = await GetAdvanceSearchContacts(
        { pageIndex: 0, pageSize: 2, isForLog },
        body
      );

      if (!(res && res.status && res.status !== 200)) {
        if (res && res.totalCount > 0) {
          if (
            res &&
            res.result &&
            res.result.length &&
            res.result[0].contactJson
          ) {
            setContactDetails({
              result: ((res && res.result) || []).map(
                (item) =>
                  item.contactJson &&
                  ContactsMapper(item, JSON.parse(item.contactJson).contact)
              )?.[0],
              totalCount: (res && res.totalCount) || 0,
            });
          }
        }
      }
    } catch (err) {
      setContactDetails({
        result: {},
        totalCount: 0,
      });
      console.error("Failed to fetch contact:", err);
    } finally {
      setIsContactDetailsLoading(false);
    }
  }, [contactId]);

  useEffect(() => {
    if (contactId) {
      getContactDetails();
    }
  }, [contactId]);

  return (
    <>
      <CustomBreadCrumb
        breadcrumbs={[
          { label: "CRM", nonClickable: true },
          { label: "Contact", link: "/home/Contacts-CRM/view" },
        ]}
        containerClasess={styles.breadCrumbContainer}
      >
        <CustomBadge
          Style={{
            padding: "4px 8px",
            borderRadius: "6px",
            lineHeight: "20px",
          }}
          label={`${
            contactDetails?.result?.company_name
              ? `${contactDetails?.result?.company_name}`
              : `${contactDetails?.result?.first_name || ""} 
            ${contactDetails?.result?.last_name || ""}`
          } #${contactId ?? ""}`}
          BackgroundColor={palette.breadcrumbs.brand_bg_hover}
          BorderColor={palette.breadcrumbs.brand_bg_hover}
          Color={palette.breadcrumbs.brand_fg_hover}
        />
      </CustomBreadCrumb>

      <PageTitle
        title={translate("contact_overview")}
        subTitle={translate("contact_overview_subTitle")}
        hideDivider
        showBackIcon
      >
        <CustomButton
          boxShadow="xs"
          size="lg"
          variant="outlined"
          color="primary"
          startIcon={
            <EditIcon width="20" height="20" fill={palette.button.primary_fg} />
          }
        >
          {translate("edit")}
        </CustomButton>
      </PageTitle>

      <CustomButton
        boxShadow="none"
        variant="text"
        size="md"
        color="tertiary"
        startIcon={
          <ClockIcon width="20" height="20" fill={palette.button.tertiary_fg} />
        }
        style={{ marginTop: "10px", marginInlineStart: "36px" }}
      >
        {translate("history")}
      </CustomButton>

      <Box
        className={clsx(styles.overviewWrapper, {
          [styles.overviewWrapperExpanded]: isExpanded && isDesktop,
        })}
      >
        <Box
          className={clsx(styles.profileSide, {
            [styles.profileSideExpanded]: isExpanded && isDesktop,
          })}
        >
          {isContactDetailsLoading && (
            <>
              <ContactProfileOverviewSkeleton />
            </>
          )}
          {!isContactDetailsLoading && !!contactDetails?.result && (
            <ContactProfileOverview details={contactDetails.result} />
          )}

          {isDesktop && (
            <>
              {isContactDetailsLoading ? (
                <SocialProfilesSkeleton numberOfCards={6} />
              ) : (
                <SocialProfiles contactDetails={contactDetails.result} />
              )}
            </>
          )}
        </Box>

        <Box
          className={clsx(styles.tabsSide, {
            [styles.tabsSideExpanded]: isExpanded && isDesktop,
          })}
        >
          <CustomTabs
            tabValue={tabValue}
            onTabChange={handleTabChange}
            tabs={contactOverviewTabs}
            variant="overview"
          />
        </Box>
      </Box>
    </>
  );
}

export default ContactOverview;
